* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

html {
  width: 100%;
  min-height: 100%;
}

html,
body {
  min-height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}

body {
  width: 450px;
  &.admin-layout {
    width: 900px;
  }
}

#root {
  min-height: 100%;
}

.app {
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  position: relative;
}

.app-wrapper {
  min-height: 100%;
}
.auth-app {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-app-wrapper {
  width: 500px;
  height: 812px;
  background-color: #f9f9f9;
}

.p-0 {
  padding: 0;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-12px {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-20px {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.gap-20px {
  gap: 20px !important;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.d-block {
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline-block {
  display: inline-block;
}

.w-100 {
  width: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.d-inline-flex {
  display: inline-flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.reply-section {
  .MuiInputBase-root {
    border: 1px solid #D0D5DD;
    background: #fff;
    padding: 10px 14px;
    margin-left: 0px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
  }

  .MuiInputBase-inputMultiline {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
  }

  .MuiButtonBase-root, MuiButtonBase-root:hover {
    background: transparent !important;
    box-shadow: none !important;
    color: #2460B8 !important;
    width: auto;
    justify-content: flex-end;
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins';
    text-transform: capitalize;
  }
}

/* Custom Dropdown Styles */
.cs-dropdown-menu {
  margin-top: 35px;
}
.cs-dropdown-menu .MuiPaper-root {
  border: 1px solid #eee;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 10px 7px 10px;
  min-width: 120px;
}
.cs-dropdown-menu .MuiPaper-root .MuiList-root {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0;
}
.cs-dropdown-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root {
  background-color: #fff;
  min-height: auto;
  height: 18px;
  line-height: 18px;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
.cs-dropdown-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root .MuiTypography-root {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins;
  color: #444;
}
.cs-dropdown-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root .MuiTypography-root.color-danger {
  color: #b83f24;
}
.cs-dropdown-menu .MuiPaper-root .MuiList-root .MuiMenuItem-root .MuiTouchRipple-root {
  display: none;
}

#switch-account .MuiList-root {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

#switch-account .MuiList-root .MuiListItem-root {
  border: 1px solid #CFCFCF;
  border-radius: 10px;
  padding: 8px;
}

#switch-account .MuiList-root .MuiListItem-root:hover {
  background: transparent !important;
}

#switch-account .MuiList-root .MuiListItem-root .MuiAvatar-root {
  width: 32px;
  height: 32px;
}

#switch-account .MuiTypography-root {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins';
}

#switch-account .MuiFormControlLabel-root {
  margin: 0;
  border: none;
}

#switch-account .MuiFormControlLabel-root .MuiRadio-root {
  background: transparent !important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.text-center {
  text-align: center;
}